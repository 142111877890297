import React from "react"
import { Line } from "react-chartjs-2"

import ReportLayout from "../../components/ReportLayout/ReportLayout"

const PriceHistoryPage = ({ location }) => (
  <ReportLayout urlSearch={location.search}>
    {({ report }) => {
      const years = report.hist.map(x => new Date(x.mnt).getFullYear())
      const annualisedYears = report.hist
        .filter(x => x.growth_12mnts$ && x.growth_12mnts)
        .map(x => new Date(x.mnt).getFullYear())

      const historicalPriceChartData = createChartData(
        years,
        report.hist.map(x => x.tv),
        "Historical Price"
      )

      const cumulativeGrowthChartData = createChartData(
        years,
        report.hist.map(x => x.growth),
        "Cumulative Growth %"
      )

      const annualGrowthDollarsChartData = createChartData(
        annualisedYears,
        report.hist.filter(x => x.growth_12mnts$).map(x => x.growth_12mnts$),
        "Annual Growth $"
      )

      const annualGrowthPercentageChartData = createChartData(
        annualisedYears,
        report.hist.filter(x => x.growth_12mnts).map(x => x.growth_12mnts),
        "Annual Growth %"
      )

      return (
        <div className="container">
          <Line data={historicalPriceChartData} />
          <Line data={cumulativeGrowthChartData} />
          <Line data={annualGrowthDollarsChartData} />
          <Line data={annualGrowthPercentageChartData} />
        </div>
      )
    }}
  </ReportLayout>
)

// ============================================================================
// HELPERS
// ============================================================================

const createChartData = (labels, data, label) => ({
  labels,
  datasets: [
    {
      label,
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(255,99,132,0.4)",
      borderColor: "#FF6384",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#FF6384",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#FF6384",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data,
    },
  ],
})

export default PriceHistoryPage
